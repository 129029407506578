import * as React from "react";
import { useState } from "react";
import {
  ItemUl,
  ItemLi,
  CustomContainer,
  CustomField,
  OptionalText,
} from "./custom.style";
interface IInputProps {
  type?: string;
  label?: string;
  error?: string;
  onChange?: (event: any) => void;
  onBlur?: () => void;
  onKeyDown?: (event: any) => void;
  onKeyUp?: (event: any) => void;
  placeholder?: string;
  defaultValue?: string;
  items?: any[];
  isOptional?: boolean;
  [key: string]: any;
}

export const CustomInput: React.FunctionComponent<IInputProps> = ({
  type,
  label,
  onChange,
  onBlur,
  error,
  placeholder = "Placeholder",
  defaultValue,
  isOptional = false,
  items,
  autoComplete,
  ...rest
}: IInputProps) => {
  const [focus, setfocus] = useState(false);
  const [itemValue, setItemValue] = useState(items);
  let itemRef: any = null;
  let mainEvent: any = null;

 
  const onFocusInput = () => {
    const onFocusVar = !focus;
    if (itemRef) itemRef.scrollTop = 0;
    setfocus(onFocusVar);
    setItemValue(items);
  };

  const setupRef = (ref: any) => {
    itemRef = ref;
  };

  const onSelectItem = (item: any) => {
    const selected = { ...mainEvent, selectedValue: item };
    if (onChange) onChange(selected);
    setfocus(false);
  };

  const onChangeText = (evt: any) => {
    mainEvent = evt;
    if (onChange) onChange(mainEvent);
    const { value } = evt.currentTarget;
    const resultItem =
      items &&
      items.filter((item) => item.name.toLowerCase().indexOf(value) !== -1);
    setItemValue(resultItem);
  };

  const onSetFocusOur = () => {
    if (items) setfocus(false);
    if (onBlur) onBlur();
  };

  return (
    <CustomContainer
      id='customContainer'
      label={label}
      errorLabel={error}
      onBlur={() => onSetFocusOur()}
    >
      {isOptional ? <OptionalText>(Optional)</OptionalText> : ""}
      <CustomField
        type={type}
        placeholder={placeholder}
        onChange={(evt: any) => onChangeText(evt)}
        onFocus={() => !!items && onFocusInput()}
        isError={!!error}
        value={defaultValue}
        autoComplete={autoComplete}
        {...rest}
      />
      {!!itemValue && (
        <ItemUl ref={(ref: any) => setupRef(ref)} isActive={focus}>
          {itemValue.map((item: any) => (
            <ItemLi key={item.id} onClick={() => onSelectItem(item)}>
              {item.name}
            </ItemLi>
          ))}
        </ItemUl>
      )}
    </CustomContainer>
  );
};

export default CustomInput;
