import styled from 'styled-components';

import NonPassiveTouchTarget from './NonPassiveTouchTarget';

export const Container = styled(NonPassiveTouchTarget)`
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    touch-action: pan-y;
    max-width: 100%;
`;

export const Track = styled(NonPassiveTouchTarget)`
  display: flex;
  height: 100%;
`;

export const PaginationWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    justify-content: center;
    pointer-events: none;
`;

export const Pagination = styled.ol`
    list-style: none;
    display: flex;
    justify-content: space-around;
    width: 180px;
    padding: 0;
    &>li {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba(50, 50, 50, 0.5);
        &.current {
        border-radius: 0;
        background: white;
      }
    }
`;
