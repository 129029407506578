import * as React from 'react';
import { useState } from 'react';
import CustomInput from '../../../ui/input/custom';
import {
  SearchContainer,
  SearchDropdownContainer,
} from './search.style';
import { XButton } from '../../../global.style';

interface ISearchProps {
  children?: React.ReactNode
  onSearch?: (value: string) => void
  onBlurSelect?: () => void
  value?: string;
  isLogDonation?: boolean
  backgroundProps?:any
}

export const SearchBar: React.FunctionComponent<ISearchProps> = (
  props: ISearchProps,
) => {
  const { children, onSearch = () => {}, value, backgroundProps} = props;
  const [state, setState] = useState({
    isShowResult: !!value,
    searchValue: value,
  });

  const onTextSearchChange = (event: any) => {
    const { value: text } = event.currentTarget;
    setState((prevState: any) => (
      {
        ...prevState,
        isShowResult: (text.length > 0),
        searchValue: text,
      }));
    onSearch(text);
  };

  const onCloseSearch = () => {
    setState((prevState: any) => (
      {
        ...prevState,
        isShowResult: false,
        searchValue: '',
      }));
    onSearch('');
  };

  const { isShowResult, searchValue } = state;

  return (
    <SearchContainer tabIndex={0}>
      <i className="icon-search" />
      <CustomInput
        type="text"
        label=""
        data-cy='enter-charity'
        placeholder="Search Charities"
        onChange={onTextSearchChange}
        defaultValue={searchValue}
      />
      { isShowResult && <XButton onClick={onCloseSearch} />}
      <SearchDropdownContainer
        isOpen={isShowResult && !!children}
        onBackgroundClick={onCloseSearch}
        onEscapeKeydown={onCloseSearch}  
        backgroundProps={backgroundProps}
      >
        {children}
      </SearchDropdownContainer>
    </SearchContainer>
  );
};

export default SearchBar;
