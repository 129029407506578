import * as React from 'react';
import {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import dynamic from 'next/dynamic';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { useDebouncedCallback } from 'use-debounce';
import SearchBar from '../../pages/browse/search';
import GET_CAMPAIGNS from './GET_CAMPAIGNS.graphql';
// import logger from '../../../helpers/logger';
import { withApollo } from '../../../helpers/apollo';
import { Context } from '../../../helpers/context';
import CREATE_EVENT_CLICK from './CREATE_EVENT_CLICK.graphql';
import { eventTypes } from '../../../constants';

const SearchRender = dynamic(
  () => import("../../pages/browse/search/searchRender")
);
// const { log } = logger("withData:search");

const USER_INPUT_DELAY = 300;

export const SearchData: React.FunctionComponent<any> = ({
  onSearching = () => {},
  isLogDonation = false,
  setCurrentCharity,
  isMakeDonation= false,
  backgroundProps = {}
}: any) => {
  const {
    updateContext,
    context: { search = { searchTerm: "" }, auth },
  } = useContext(Context);
  const searchTerm = useRef(search.searchTerm);
  const [showSearch, setShowSearch] = useState(!!search.searchTerm);
  const [doSearch, { data, error, loading }] = useLazyQuery(GET_CAMPAIGNS);
  const [doCreateEvent] = useMutation(CREATE_EVENT_CLICK);

  const debouncedIsSearching = useDebouncedCallback(
    (val:boolean) => {
      setShowSearch(val);
      onSearching(val);
    }, USER_INPUT_DELAY,
  );

  const debouncedDoSearch = useDebouncedCallback(
    (val:any) => {
      doSearch({
        variables: {
          searchTerm: val,
        },
      });
    },
    USER_INPUT_DELAY,
  );

  const onSearch = (value: string) => {
    if (value.length) {
      searchTerm.current = value;
      debouncedIsSearching(true);
      debouncedDoSearch(value);
    }
    if (value === "") {
      debouncedIsSearching(false);
      searchTerm.current = value;
    }
  };

  const onCreateNewCharity = async (newData: any) => {
    if (isLogDonation) {
      return;
    }

    const { charityName, email, isResponsible, location, number } = newData;
    const awsMsg = JSON.stringify({
      category: "CreateCharity",
      action: "create",
      label: charityName.value,
      email: email.value,
      isResponsible: isResponsible.value,
      location: location.value,
      number: number.value,
    });
    await doCreateEvent({
      variables: {
        messageType: eventTypes.CMP_POST,
        message: awsMsg,
      },
    });
  };

  // On mount
  useEffect(() => {
    if (search.searchTerm === searchTerm.current && search.searchTerm) {
      // Applying saved search back to component
      onSearch(search.searchTerm);
    }
  }, [searchTerm, search.searchTerm]);

  // On unmount
  useEffect(
    () => () => {
      if (searchTerm.current !== search.searchTerm) {
        // Saving search data to context
        updateContext({
          page: "search",
          data: {
            searchTerm: searchTerm.current,
          },
        });
      }
    },
    [searchTerm]
  );

  // log(error);
  return (
    <SearchBar
      onSearch={onSearch}
      value={search.searchTerm}
      isLogDonation={isLogDonation}
      backgroundProps={backgroundProps}
    >
      {showSearch ? (
        <SearchRender
          auth={auth}
          data={data}
          onCreateNewCharity={onCreateNewCharity}
          loading={loading}
          isLogDonation={isLogDonation}
          isMakeDonation={isMakeDonation}
          setCurrentCharity={(e) => {
            setCurrentCharity(e);
            setShowSearch(false);
            searchTerm.current = ""
          }}
          searchTerm={searchTerm.current}
        />
      ) : null}
    </SearchBar>
  );
};

export default withApollo()(SearchData);
