import styled, { css, keyframes } from 'styled-components';

export const CustomContainer = styled.div`
  ${({ theme: { primary, font, danger }, label, errorLabel }: any) => css`
    display: block;
    position: relative;
    font-weight: ${font.weight.bold};
    color: ${primary};
    font-size: ${font.size.text.sm};
    line-height: 16px;
    outline: none;
    margin-bottom: 1.5rem;
    &{
      ::before {
        text-transform: uppercase;
        display: inline-block;
        content: '${label}';
        margin: 0 0 5px;
      }

      ::after {
        position: absolute;
        display: block;
        content: '${errorLabel}';
        margin: 5px 0 0;
        color: ${danger};
        font-weight: normal;
        bottom: -10px;
        opacity: 0;
        user-select: none;
        transition: all .5s cubic-bezier(0.65, 0, 0.35, 1);
        ${!!errorLabel && `
          bottom: -20px;
          opacity: 1;
        `}
      }
    }
  `}
`;

export const CustomField = styled.input`
  ${({
    theme: {
      shades, font, secondary, danger,
    }, isError, upperCase,
  }: any) => css`
    height: 44px;
    width: 100%;
    font-style: normal;
    font-weight: ${font.weight.normal};
    font-size:  ${font.size.text.md};
    line-height: 44px;
    padding: 0 0.5rem;
    color: ${shades[4]};
    transition: ease-in-out, width .35s ease-in-out;
    outline: none;
    border: 1px solid ${isError ? danger : shades[3]};
    text-transform: ${upperCase && 'uppercase'};
    -webkit-appearance:none; 
    -webkit-border-radius:0;
    & {
      ::placeholder {
        color: ${shades[3]};
        font-size:  ${font.size.text.md};
        line-height: 44px;
        padding:0 5px 10px;
      }

      :focus {
        border: 1px solid ${isError ? danger : secondary};
      }
    }
  `}
`;

const ON = keyframes`
  0% {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }

  1% {
    visibility: visible;
  }

  80% {
    opacity: 1;
  }

  100% {
    max-height: 300px;
  }
`;

const OFF = keyframes`
  0% {
    max-height: 300px;
  }

  40% {
    opacity: 1;
  }

  100% {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }
`;

export const ItemUl = styled.ul`
  ${({ theme: { boxShadow, shades }, isActive }: any) => css`
      display: block;
      position: absolute;
      top: 65px;
      left: 0;
      padding: 0;
      margin: 0;
      background-color: ${shades[2]};
      width: 100%;
      margin-top: 5px;
      box-shadow: ${boxShadow && boxShadow.md};
      z-index: 1000;
      max-height: 0;
      overflow: auto;
      animation: ${isActive ? ON : OFF};
      animation-duration: 0.6s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    `}
`;

export const ItemLi = styled.ul`
  ${({ theme: { shades, font } }: any) => css`
    display: block;
    background-color: ${shades[0]};
    width: 100%;
    height: 44px;
    margin-bottom: 1px;
    padding: 12px 16px;
    font-weight: ${font.weight.normal};
    font-size: ${font.size.text.md};
    line-height: 20px;
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    color: ${shades[4]};
    z-index: 9999;

    & {
      :hover {
        background-color: ${shades[1]};
      }
    }
  `}
`;

export const OptionalText = styled.span`
padding: 7px;
font-weight: normal;
`;
