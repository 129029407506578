import * as React from 'react';
import dynamic from 'next/dynamic';

import touchWithMouseHOC from 'react-touch-carousel/lib/touchWithMouseHOC';

import {
  Container,
  Track,
  PaginationWrapper,
  Pagination,
} from './carousel.style';

const TouchCarousel: any = dynamic(
  () => import('react-touch-carousel'),
  { ssr: false },
);

export const POSITION = {
  MIDDLE: 2,
  LEFT: 6.5,
  RIGHT: 1,
};

interface ICarouselProps {
  loop?: boolean;
  autoplay?: boolean;
  cardSize?: number;
  carouselWidth?: number;
  cardPadCount?: number;
  children: React.ReactNode[];
  onRest?: (data:any) => any;
  onDragStart?: (data:any) => any;
  onDragEnd?: (data:any) => any;
  onDragCancel?: (data:any) => any;
  pagination?: boolean;
  startFrom?: number
}

const Carousel = ({
  loop = false,
  autoplay = false,
  cardSize = 300,
  carouselWidth = 375,
  cardPadCount = 0,
  children,
  onRest = () => {},
  onDragStart = () => {},
  onDragEnd = () => {},
  onDragCancel = () => {},
  pagination = false,
  startFrom = POSITION.MIDDLE,
}:ICarouselProps) => {
  let current = 0;

  const renderCard = (_index:any, modIndex:any) => {
    const child:any = children[modIndex];
    const isActive = modIndex === current ? 'active' : '';

    const lastIndex = current - 1 < 0 ? null : current - 1;
    const isPrev = modIndex === lastIndex ? 'prev' : '';

    const nextIndex = current + 1 >= children.length ? null : current + 1;
    const isNext = modIndex === nextIndex ? 'next' : '';

    return child && React.cloneElement(child, {
      className: `${isActive} ${isPrev} ${isNext}`,
    });
  };

  const clamp = (n: number, min: number, max: number) => {
    if (n < min) {
      return min;
    }
    if (n > max) {
      return max;
    }
    return n;
  };

  const getWidth = () => {
    if (typeof window !== 'undefined') {
      return clamp(window.innerWidth, 0, carouselWidth);
    }
    return carouselWidth;
  };

  const carouselContainer = (props:any) => {
    const width = getWidth();
    const { cursor, carouselState: { active, dragging }, ...rest } = props;
    current = -Math.round(cursor) % children.length;
    while (current < 0) {
      current += children.length;
    }
    // Put current card at center
    const translateX = (cursor - cardPadCount) * cardSize + (width - cardSize) / startFrom;

    return (
      <Container
        $isActive={active}
        $isDragging={dragging}
      >
        <Track
          style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
          {...rest}
        />

        {pagination ? (
          <PaginationWrapper>
            <Pagination>
              {children.map((_, index) => (
                <li
                  key={`${index + 1}`}
                  className={current === index ? 'current' : ''}
                />
              ))}
            </Pagination>
          </PaginationWrapper>
        ) : ''}
      </Container>
    );
  };

  return (
    <>
      <TouchCarousel
        component={touchWithMouseHOC(carouselContainer)}
        cardSize={cardSize}
        cardCount={children.length}
        cardPadCount={cardPadCount}
        loop={loop}
        autoplay={autoplay ? 2e3 : false}
        renderCard={renderCard}
        onRest={onRest}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragCancel={onDragCancel}
      />
    </>
  );
};

export default Carousel;
